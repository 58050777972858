'use strict';

import { ILogService, IRootScopeService, IScope, ITimeoutService } from "angular";
import {AccountResponse, LoginTokenState, AdminLoginResetRequest} from "../../../data/account.data";
import HelperService from "../../../services/helper.service";
import RestService from "../../../services/rest.service";
import * as webauthnJson from "@github/webauthn-json";

require('./login.view.component.css');
const KEY_FIDO_LOGIN = 'login.fido';


/* @ngInject */
export default class LoginController {
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  private helperService: HelperService;
  public $scope: IScope;
  public $state: any;

  public dataService: any;
  public restService: RestService;
  public errorService: any;
  public listeners = [];
  public username: string;
  public password: string;
  public keepLoggedIn: boolean = false;
  public fidoLogin: boolean = false;
  public isLoginAsAdmin: boolean = false;
  public state: LoginState = LoginState.NONE;
  public page: LoginPage = LoginPage.LOGIN;
  public result: AccountResponse;
  public licenceEmail:string;
  public licencePW:string;
  public newAdminPw:string;
  public isReseting:boolean= false;

  public code1: string;
  public code2: string;
  public code3: string;
  public code4: string;
  public code5: string;
  public code6: string;

  constructor($rootScope: IRootScopeService, $scope: IScope, $state, $log: ILogService, dataService, helperService: HelperService, errorService, restService: RestService) {
    $log.debug('LoginController started...');
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.helperService = helperService;
    this.$scope = $scope;
    this.$state = $state;
    this.dataService = dataService;
    this.restService = restService;
    this.errorService = errorService;

    this.fidoLogin = this.helperService.getFromStorage(KEY_FIDO_LOGIN, 'false') === 'true';

    //Already logged in
    this.listeners.push(this.$rootScope.$on('new.account', () => {
      //Switch scope to home
      element.style.background = '';
      this.$state.go(this.getOverviewPath());
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });

    // Set background image
    var element = document.body;
    element.style.background = 'url(' + this.getStaticImage('background.jpg') + ') no-repeat center center fixed';
  }

  toggleFido() {
    this.fidoLogin = !this.fidoLogin;
    this.helperService.saveInStorage(KEY_FIDO_LOGIN, this.fidoLogin + '');
  }

  /**
      Login to server
    */
  login() {
    if (this.isLoginAsAdmin) {
      this.username = 'Admin';
    }

    if (this.username === '') {
      return;
    }

    if (this.fidoLogin) {
      this.loginViaFido();
      return;
    }

    if (this.password === '') {
      return;
    }

    this.$log.info('Angemeldet bleiben: ' + this.keepLoggedIn);

    //Load
    this.state = LoginState.LOADING;

    this.restService.login(this.username, this.password, this.keepLoggedIn).then(result => {
      this.state = LoginState.OK;
      this.result = result;

      switch (result.state) {
        case LoginTokenState.OK:

          // Add animation

          document.getElementById('login-container').classList.add('slide-out-bck-center');

          document.body.style.background = ''; // Delete custom background
          this.dataService.setAccount(result.account);
          this.$state.go(this.getOverviewPath());
          break;
        case LoginTokenState.FIRST_SECOND_FACTOR_LOGIN:
          // Display QR code
          this.state = LoginState.SHOW_QR;
          this.page = LoginPage.QR_CODE;

          // Focus on first input field
          this.focusOnFirst2FAInput();
          break;
        case LoginTokenState.SECOND_FACTOR_REQUIRED:
          // Show input for 2FA
          this.state = LoginState.SECOND_FACTOR;
          this.page = LoginPage.CODE;

          // Focus on first input field
          this.focusOnFirst2FAInput();
          break;
      }
    }).catch(err => {

      switch (err.status) {
        case 403:
          // Invalid login
          this.state = LoginState.FORBIDDEN;
          break;
        default:
          this.state = LoginState.ERROR;
          break;
      }
    }).finally(() => {
      this.$scope.$applyAsync();
    });
  };

  focusOnFirst2FAInput() {
    window.setTimeout(() => {
      let codeInput = document.getElementById('code1');
      if (codeInput) {
        codeInput.focus();
      }
    }, 50);
  }

  reset2FA() {
    this.code1 = '';
    this.code2 = '';
    this.code3 = '';
    this.code4 = '';
    this.code5 = '';
    this.code6 = '';
  }

  isInternal(){
    return location.hostname === "localhost" || location.hostname === "127.0.0.1";
  }

  setResetAdminCredentialsPage(){
    this.page = LoginPage.RESET;
  }
  backToLogin(){
    this.resetData();
    this.page= LoginPage.LOGIN;
  }
  reset(){
    this.isReseting= true;
    var resetRequest= {licenceEMail: this.licenceEmail,
      licencePW: this.licencePW} as AdminLoginResetRequest;
    this.restService.resetLoginFromAdmin(resetRequest).then((newPassword)=>{
        this.newAdminPw= newPassword.newAdminPw;
    }).finally(()=>{
      this.isReseting= false;
      this.$scope.$applyAsync();
    })
  }

  resetData(){
    this.newAdminPw=undefined;
    this.licencePW=undefined;
    this.licenceEmail= undefined;
    this.username= undefined;
    this.password = undefined;
  }


  /**
   * Get an static image
   */
  getStaticImage(imageName: string) {
    return this.restService.getStaticImageUrl(imageName);
  }

  /**
   * Event which will be triggerd if user inputs another code
   * @param currentCode The current input field
   */
  codeEntered(currentCode: number) {

    let doc = (<HTMLInputElement>document.getElementById('code' + currentCode));
    let currentValue = doc.value;

    // This code is there to enable entering the 88842code fluently even if a value was deleted or the cursor is in a already filled field
    if (currentValue.length > 1) {
      switch (currentCode) {
        case 1:
          // When entering a number in a field that already has a value (length == 2), split the value, override the current value with the first
          // number and set the second number into the next field
          this.code1 = currentValue.substring(0, 1);
          this.code2 = currentValue.substring(1);
          break;
        case 2:
          this.code2 = currentValue.substring(0, 1);
          this.code3 = currentValue.substring(1);
          break;
        case 3:
          this.code3 = currentValue.substring(0, 1);
          this.code4 = currentValue.substring(1);
          break;
        case 4:
          this.code4 = currentValue.substring(0, 1);
          this.code5 = currentValue.substring(1);
          break;
        case 5:
          this.code5 = currentValue.substring(0, 1);
          this.code6 = currentValue.substring(1);
          break;
        case 6:
          this.code6 = currentValue.substring(0, 1);
          break;
      }

      document.getElementById('code' + ++currentCode).focus();
    }


    if (currentCode === 6) {
      // Last entry, do validation
      this.state = LoginState.LOADING;
      this.$scope.$applyAsync();

      let code = this.code1 + this.code2 + this.code3 + this.code4 + this.code5 + this.code6;
      this.restService.validate2FA(this.username, code, this.result.oneTimeToken, this.keepLoggedIn, this.result.account.hasWeakPW).then(result => {
        // Result OK
        document.body.style.background = ''; // Delete custom background
        this.dataService.setAccount(result.account);
        this.$rootScope['account'] = result.account;
        this.$state.go('main.home');
      }).catch(err => {
        this.reset2FA();
        switch (err.status) {
          case 403:
            this.state = LoginState.FORBIDDEN;

            // Add animation classes
            let qrContainer = document.getElementById('qr-container');
            qrContainer.classList.remove('flip-in-hor-bottom');
            qrContainer.classList.remove('wobble-hor-bottom');
            window.setTimeout(() => {
              qrContainer.classList.add('wobble-hor-bottom');
            }, 50);
            this.focusOnFirst2FAInput();
            break;
          case 406:
            // Request not valid anymore
            this.state = LoginState.NONE;
            this.page = LoginPage.LOGIN;
            this.password = '';
            document.getElementById('inputPassword').focus();
            this.errorService.notify(this.errorService.ERROR_IDS.LOGIN_NOT_VALID);
            break;
          default:
            this.state = LoginState.ERROR;
            break;
        }
      }).finally(() => {
        this.$scope.$applyAsync();
      });


    } else {

      if (currentValue === '') {
        // Removed a char, go back one field
        if (currentCode > 0) {
          document.getElementById('code' + --currentCode).focus();
        }
      }
    }
  }


  /**
   * Login via FIDO2
   */
  loginViaFido() {
    this.state = LoginState.LOADING;
    this.restService.fidoStartAuthentication(this.username).then(async (res) => {
      this.$log.info('Got start result from server!');
      webauthnJson.get(res).then(publicKeyCredential => {
        this.$log.info('Got publicKeyCredential via FIDO2!');
        this.restService.fidoFinishAuthentication(this.username, publicKeyCredential).then(accountResponse => {
          this.state = LoginState.OK;
          this.result = accountResponse;
          document.getElementById('login-container').classList.add('slide-out-bck-center');
          document.body.style.background = ''; // Delete custom background
          this.dataService.setAccount(accountResponse.account);
          this.$state.go('main.home');
        }).catch(err2 => {
          this.$log.error('Failed to finish authentication', err2);
          this.state = LoginState.FORBIDDEN;
          this.$scope.$applyAsync();
        });
      }).catch(err => {
        this.$log.error('Failed to start authentication', err);
        this.state = LoginState.FORBIDDEN;
        this.$scope.$applyAsync();
      });
    }).catch(err => {
      this.$log.error('Failed to start authentication', err);
      this.state = LoginState.FORBIDDEN;
      this.$scope.$applyAsync();
    });
  }

  private getOverviewPath(): string {
    return this.helperService.getFromStorage("overviewPath", "main.home");
  }

}

enum LoginPage {
  LOGIN = 'LOGIN',
  QR_CODE = 'QR_CODE',
  CODE = 'CODE',
  RESET= 'RESET'
}


enum LoginState {
  NONE = 'NONE',
  LOADING = 'LOADING',
  FORBIDDEN = 'FORBIDDEN',
  FORBIDDEN_2FA = 'FORBIDDEN_2FA',
  ERROR = 'ERROR',
  SECOND_FACTOR = 'SECOND_FACTOR',
  SHOW_QR = 'SHOW_QR',
  OK = 'OK'
}